<template>
  <TransitionGroup name="fade">
    <ExecutionPeriodRadioField
      key="execution-period"
      :choices="choices"
      @result="onExecutionPeriodSelected"
      v-on="$listeners"
    />

    <div v-show="showDatePicker" key="move-preferred-date" class="mt-4">
      <MovePreferredDateField
        ref="datePicker"
        default-empty-value
        v-on="$listeners"
      />
    </div>
  </TransitionGroup>
</template>

<script>
import { Selectable } from 'chimera/all/components/models/Selectable'
import MovePreferredDateField from 'chimera/all/components/form/fields/movePreferredDate/MovePreferredDateField'
import ExecutionPeriodRadioField from 'chimera/all/components/form/fields/executionPeriod/ExecutionPeriodRadioField'

export default {
  name: 'MovePreferredDateRadioFormPart',

  components: {
    MovePreferredDateField,
    ExecutionPeriodRadioField
  },

  /**
   * @returns {object}
   */
  data () {
    return {
      flexibleDate: 'Flexibel / in overleg',
      specificDate: 'Specifieke datum',
      showDatePicker: undefined,
      choices: [
        new Selectable(this.flexibleDate, this.flexibleDate, this.flexibleDate),
        new Selectable(this.specificDate, this.specificDate, this.specificDate)
      ]
    }
  },

  /**
   * Set flexible and specific date before component is created
   */
  beforeCreate () {
    this.flexibleDate = this.$i18n.t('field.move-preferred-date.flexibleDate')
    this.specificDate = this.$i18n.t('field.move-preferred-date.specificDate')
  },

  methods: {
    /**
     * @param {object} event
     */
    onExecutionPeriodSelected (event) {
      if (!event.isValid) {
        return
      }

      const executionPeriod = event.value[0]
      switch (executionPeriod.id) {
        case this.flexibleDate:
          this.showDatePicker = false
          this.$refs.datePicker.setValue(executionPeriod.value)
          break
        case this.specificDate:
          /**
           * Only reset the date picker when it is follow up to a flexible date:
           * - undefined: when the form is loaded
           * - false: user selected a flexible date before
           * - true: user selected a specific date before
           */
          if (this.showDatePicker === false) {
            // Note: using setValue would instantly validate the date picker and show an error
            this.$refs.datePicker.value = undefined
            this.$refs.datePicker.reset()
          }

          this.showDatePicker = true

          setTimeout(() => {
            this.scrollToDatePicker()
          }, 250)
          break
      }
    },

    /**
     * Scroll the viewport to the date picker
     */
    scrollToDatePicker () {
      const datePicker = this.$refs.datePicker?.$el
      if (!datePicker) {
        return
      }

      const datePickerTop = datePicker.getBoundingClientRect().top
      const datePickerHeight = datePicker.getBoundingClientRect().height
      const datePickerBottom = datePickerTop + datePickerHeight

      const windowTop = window.scrollY
      const windowBottom = windowTop + window.innerHeight

      if (datePickerTop < windowTop || datePickerBottom > windowBottom) {
        datePicker.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }
}
</script>

<style lang="scss">
.field-move-preferred-date-error {
  .vdpOuterWrap {
    @apply border border-error;
  }
}
</style>
