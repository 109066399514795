<template>
  <div :class="[`field-${field}`]">
    <TSelectableGroup
      v-model="value"
      :choices="choices"
      :error-messages="errors"
    />
    <FormErrorMessages :error-messages="errors" class="my-3" />
  </div>
</template>

<script>
import FormErrorMessages from 'chimera/all/components/form/FormErrorMessages'
import TSelectableGroup from 'chimera/all/components/elements/TSelectableGroup'
import AbstractTSelectableGroupFormField from 'chimera/all/components/form/fields/AbstractTSelectableGroupFormField'

export const field = 'execution-period'
export default {
  name: 'ExecutionPeriodRadioField',

  components: {
    TSelectableGroup,
    FormErrorMessages
  },

  extends: AbstractTSelectableGroupFormField,

  props: {
    field: {
      type: String,
      default: field
    }
  }
}
</script>
