<template>
  <MovePackingConditionsRadioField
    :choices="choices"
    v-on="$listeners"
  />
</template>

<script>
import MovePackingConditionsRadioField from 'chimera/all/components/form/fields/movePackingConditions/MovePackingConditionsRadioField'
import { Selectable } from 'chimera/all/components/models/Selectable'

export default {
  name: 'MovePackingConditionsFormPart',

  components: {
    MovePackingConditionsRadioField
  },

  /**
   * @returns {object}
   */
  data () {
    return {
      choices: [
        new Selectable(this.$t('packEverything'), this.$t('packEverything'), this.$t('packEverything')),
        new Selectable(this.$t('packFragileOnly'), this.$t('packFragileOnly'), this.$t('packFragileOnly')),
        new Selectable(this.$t('packNothing'), this.$t('packNothing'), this.$t('packNothing'))
      ]
    }
  }
}
</script>

<i18n>
{
  "nl-NL": {
    "packEverything": "Alles inpakken",
    "packFragileOnly": "Alleen breekbare spullen inpakken",
    "packNothing": "Niets inpakken"
  },
  "nl-BE": {
    "packEverything": "Alles inpakken",
    "packFragileOnly": "Alleen breekbare spullen inpakken",
    "packNothing": "Niets inpakken"
  },
  "es-ES": {
    "packEverything": "Todo",
    "packFragileOnly": "Solo elementos frágiles",
    "packNothing": "Nada"
  },
  "de-DE": {
    "packEverything": "Alles einpacken",
    "packFragileOnly": "Nur zerbrechliche Gegenstände verpacken",
    "packNothing": "Nichts einpacken"
  },
  "fr-BE": {
    "packEverything": "Emballez tout",
    "packFragileOnly": "Emballez uniquement des objets fragiles",
    "packNothing": "Rien à emballer"
  },
  "fr-FR": {
    "packEverything": "Emballez tout",
    "packFragileOnly": "Emballez uniquement des objets fragiles",
    "packNothing": "Rien à emballer"
  }
}
</i18n>
