<template>
  <div
    :class="[
      `field-${field}`,
      validity
    ]"
  >
    <TDatePicker
      v-model="value"
      :is-date-disabled="isDateDisabled"
      :has-input-element="false"
      @input="validate(value)"
    />

    <FormErrorMessages class="my-3" :error-messages="errors" />
  </div>
</template>

<script>
import AbstractFormField from 'chimera/all/components/form/fields/AbstractFormField'
import FormErrorMessages from 'chimera/all/components/form/FormErrorMessages'

export const field = 'move-preferred-date'
export default {
  name: 'MovePreferredDateField',

  components: {
    FormErrorMessages
  },

  extends: AbstractFormField,

  props: {
    field: {
      type: String,
      default: field
    },

    defaultEmptyValue: {
      type: Boolean,
      default: false
    }
  },

  /**
   * @returns {object}
   */
  data () {
    return {
      value: undefined
    }
  },

  computed: {
    /**
     * @returns {string}
     */
    validity () {
      const hasErrors = this.errors.length > 0
      return hasErrors ? `field-${field}-error` : ''
    }
  },

  watch: {
    /**
     * @param {string} value
     */
    value (value) {
      this.reset()
    }
  },

  /**
   */
  beforeMount () {
    this.initialiseValue()
  },

  methods: {
    /**
     *
     */
    initialiseValue () {
      // Check if we should initialise the value with a default value.
      const isValidDate = this.value && !this.isDateDisabled(new Date(this.value))
      if (this.defaultEmptyValue || isValidDate) {
        return
      }

      this.value = this.getFormattedDate(this.getFirstAllowedDate())
    },

    /**
     * @returns {Date}
     */
    getFirstAllowedDate () {
      const firstAllowedDate = new Date()
      firstAllowedDate.setDate(new Date().getDate() + 1)
      return firstAllowedDate
    },

    /**
     * @param {Date} date
     * @returns {boolean}
     */
    isDateDisabled (date) {
      const beginDate = this.getFirstAllowedDate()
      beginDate.setDate(beginDate.getDate() - 1)
      return date < beginDate
    },

    /**
     * Get formatted string for date.
     * Format: YYYY-MM-DD
     *
     * @param {Date} date
     * @returns {string}
     */
    getFormattedDate (date) {
      return date.toISOString().slice(0, 10)
    }
  }
}
</script>
